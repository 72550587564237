import * as React from "react"
import Layout from "../components/layout"
import CarbonPassHeader from "../components/carbonpassheader2"
import GetInTouch from "../components/getintouch"
import CloudOne from "../images/plane.svg"
import bgsky from "../images/bg-cloud.svg"
import Seo from "../components/seo"

const Privacy = () => (
  <Layout>
      <Seo title="Privacy policy" />
      <CarbonPassHeader/>
      <div className="relative">
      <div className="-mb-8 block pt-4 p-6 text-white bg-gradient-to-b from-carbonblue to-carbonbluelight">
          <img className='animate-[movingCloud_50s_linear_infinite] opacity-20' src={CloudOne} alt="Cloud" style={{height:"140px"}}></img>
         
          <div className="max-w-7xl mx-auto h-48">

          <h2 className="mt-10 text-5xl lg:text-7xl font-bold text-white mb-8 lg:mb-12">
          Privacy policy
          </h2>

          </div>
          <div className='animate-[movingCloudRight_150s_linear_infinite] bg-cover block' style={{backgroundImage: `url(${bgsky})`, width:"4000px",height:"200px"}}></div>
         
      </div>
      <div className="px-6 max-w-7xl mx-auto pt-16">

      <div className="grid grid-cols-1">
        <div className="text-xl pb-14">
      <p>It is important that you read this privacy policy together with any other privacy policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them</p>
<h2><strong>Definitions</strong></h2>
<p>‘We’, ‘our’ or ‘us’ means Ports of Jersey.</p>
<p>‘You or ‘your’ means any person using the services of Ports of Jersey.</p>
<p>‘Information’ or ‘data’ means all the different forms of information you provide us and which we collect in carrying out our duties and in providing services to you.</p>
<h2><strong>Contact details</strong></h2>
<p>If you have any questions relating to data protection:</p>
<ul>
<li>how we use your information</li>
<li>information security</li>
<li>our privacy policy</li>
<li>wish to have your consent withdrawn, information modified, deleted or to gain access to the information held about you</li>
</ul>
<p>Please contact our Data Protection Officer via email <a href="mailto:dataprotection@ports.je"><strong>dataprotection@ports.je</strong></a> or alternatively, write to Data Protection Officer, Ports of Jersey, Jersey Airport, St Peter, Jersey, JE1 1BY.</p>
<h2><strong>Lawful basis</strong></h2>
<p>To process your personal data, we need to meet one of the following conditions (or legal bases):</p>
<ul>
<li>you have freely given your consent – it will be clear to you what you are consenting to and how you can withdraw your consent;</li>
<li>it is necessary for a contract you have entered into with us, or a contract that you intend to enter into;</li>
<li>it is necessary to meet a legal obligation;</li>
<li>it is necessary to protect someone’s ‘vital interests’ (a matter of life or death);</li>
<li>it is necessary to perform a public task (to carry out a public function or exercise powers set out in law, or to perform a specific task in the public interest that is set out in law);</li>
<li>it is necessary for our legitimate interests or that of a third party (a condition used where personal data is going to be used in ways that are reasonably expected and are not intrusive, and/or where there are compelling reasons for the processing).</li>
</ul>
<p>The lawful basis that we rely on to process your personal data will determine which of the following rights are available to you. Much of the processing we do at Ports of Jersey will be necessary to meet our legal obligations or to perform a public task. If we hold personal data about you for different purposes, then the legal basis we rely on in each case may not be the same.</p>
<h2><strong>What, how and why we collect personal data </strong></h2>
<p>The personal data which we collect and process will depend on the nature of our interaction with you. We collect your personal data to:</p>
<ul>
<li>help keep the public, our customers and employees safe and secure;</li>
<li>comply with our obligations under Tax and Customs and Immigration legislation;</li>
<li>operate any online applications and services used to promote a better customer experience;</li>
<li>comply with our operational policies and procedures and the terms of our maritime and aviation operators;</li>
<li>comply with our legal obligations and responsibilities;</li>
<li>exercise our statutory powers.</li>
</ul>
<p>We are the Data Controller of your information. We endeavour to structure our core business activities in a clear and transparent manner, reflecting the different ways we may interact with you. These include, but are not limited to:</p>
<p>We often need to collect information so that we can provide you with our full range of services and fulfil our statutory, regulatory and public obligations. Much of the information we hold comes directly from you.</p>
<p>In some cases, we gather data as part of our statutory functions, for example CCTV surveillance (including body-worn cameras) at the Harbour and Airport for security, safety and regulatory purposes. We also act as third party and joint controllers in certain instances. These are documented in Data Sharing Agreements and/or Processor Controller agreements (as required).</p>
<p>Please click on the relevant area to see the data we process, why we process the data, how we collect the data and how long we keep it for.</p>
<p><a href="http://www.ports.je/privacypolicy/marcomms/">Marketing and Communications</a></p>
<p><a href="http://www.ports.je/privacypolicy/recruitment-and-employment/">Recruitment and Employment</a></p>
<p><a href="http://www.ports.je/privacypolicy/airport-and-harbours/">Keeping the Airport and Harbours open, safe and secure</a></p>
<p><a href="http://www.ports.je/privacypolicy/contracts/">Fulfilling our contracts with you OR providing our services</a></p>
<h2><strong>What we do with your information</strong></h2>
<p>We take the security of your personal information seriously and do not share this unless it is essential to provide you with a service in performing our statutory duties. There may also be occasions when we need to share your information, in the following ways:</p>
<ul>
<li>when we have your permission;</li>
<li>when required by law to disclose it;</li>
<li>while pursuing tasks for which we have a legitimate interest to remain open, safe and secure.</li>
</ul>
<h2><strong>How we protect your information</strong></h2>
<p>We adopt up-to-date, appropriate data collection, storage and processing practices and security measures to protect against unauthorised access, alteration, disclosure or destruction of your personal information. We have a Data Protection Policy and Information Security Policy and Training Policy to ensure we adhere to the highest standards of governance.</p>
<p>The security measures we have in place include, but are not limited to:</p>
<ul>
<li>data is encrypted while at rest and sensitive documents may also be encrypted while in transit.</li>
<li>destruction process – all personal data is securely removed after it has reached the end of its retention period. Paper waste is disposed of in designated confidential waste bins.</li>
<li>physical security/access permissions – we implement an electronic access control system to physically restrict access to areas where data is stored. Access to data that is held in electronic format is managed by a username and password and access is restricted to only those users who have a valid business need.</li>
<li>policies – all staff adhere to the Acceptable Use Policy.</li>
<li>training – there is mandatory Data Protection training for all new staff and annual updates for existing staff.</li>
</ul>
<h2><strong>Retention periods</strong></h2>
<p>We keep information for only as long as necessary to fulfil the purpose for which it was collected. Retention periods are applied according to the classification of the data type and the purpose for which it is held. The relevant retention periods may be disapplied in certain situations, including where evidence is required in civil and criminal matters.</p>
<h2><strong>Cookies</strong></h2>
<p>Our site uses cookies. A cookie is a small text file that a website saves onto your device when you visit the site. The cookies on our website simply allow us to track generic usage of our website – not your individual usage behaviour. It is not used to identify you personally. Cookies don’t store any confidential information about you personally.</p>
<p>By using our site you agree to our use of cookies. We provide information on how we use cookies below.</p>
<p>Most browsers support cookies but you can set your browser to decline them and delete them whenever you like. However, some functions of our website may not work as they should if cookies are not enabled.</p>
<p>The law states that we can store cookies on your device if they are strictly necessary for the operation of our website. For all other types of cookies we need your permission.</p>
<p>Your consent applies to the following domains: ports.je and jerseyairport.com</p>
<p>Necessary cookies help to make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</p>
<table width="550">
<tbody>
<tr>
<td width="81"><strong>Cookie</strong></td>
<td width="304"><strong>Description</strong></td>
<td width="66"><strong>Duration</strong></td>
<td width="87"><strong>Type</strong></td>
</tr>
<tr>
<td width="81">_ga</td>
<td width="304">This cookie is installed by Google Analytics. The cookie is used to calculate visitor session campaign data and keep track of site usage for the site’s analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.</td>
<td width="66">2 years</td>
<td width="87">Analytics</td>
</tr>
<tr>
<td width="81">_gid</td>
<td width="304">This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in creating an analytics report of how the website is doing. The data collected including the number of visitors, the source where they have come from and the pages visited in an anonymous form.</td>
<td width="66">1 day</td>
<td width="87">Analytics</td>
</tr>
<tr>
<td width="81">_gat_UA-161859490-1</td>
<td>And _gat_UA-161859490-2</td>
<td width="304">This is a pattern type cookie set by Google Analytics, where the pattern element on the name contains the unique identify number of the account or website it relates to. It appears to be a variation of the _gat cookie which is used to limit the amount of data recorded by Google on high traffic volume websites.</td>
<td width="66">1 minute</td>
<td width="87">Performance</td>
</tr>
<tr>
<td width="81">test_cookie</td>
<td width="304">This cookie is set by doubleclick.net. The purpose of the cookie is to determine if the users’ browser supports cookies.</td>
<td width="66">15 minutes</td>
<td width="87">Advertisement</td>
</tr>
<tr>
<td width="81">ARRAffinity</td>
<td width="304">This cookie is set by websites that run on Windows Azure cloud platform. The cookie is used to ffinities a client to an instance of an Azure Web App.</td>
<td width="66"></td>
<td width="87">Necessary</td>
</tr>
</tbody>
</table>
<h2><strong>Third parties</strong></h2>
<p>Users may find plug-ins and other content on our websites and applications that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our site.</p>
<p>These sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our site, is subject to that website’s own terms and policies.</p>
<h2><strong>Third country transfers and safeguard</strong></h2>
<p>We operate in Jersey in the Channel Islands. We do use technology that may transfer data to another jurisdiction, for example use of Microsoft and cloud-based back-up of data. We will ensure that your data is appropriately protected (for example by reference to IT security standards) where such transfers do not offer the same level of protection of personal data.</p>
<h2><strong>Updates to this privacy policy and your duty to inform us of changes</strong></h2>
<p>We reserve the right to modify this privacy policy at any point. We recommend you review it frequently.</p>
<p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
<h2><strong>Your legal rights</strong></h2>
<p>Under the Data Protection (Jersey) Law 2018, you have the following rights with regards to your personal information:</p>
<ul>
<li>Right to be<strong> informed</strong> about the collection and the use of your personal data.</li>
<li>Right to <strong>access</strong> your personal data and supplementary information.</li>
<li>Right to <strong>rectification</strong> have inaccurate personal data rectified, or completed if it is incomplete.</li>
<li>Right to <strong>erasure</strong> (to be forgotten) in certain circumstances.</li>
<li>Right to <strong>restrict processing</strong> in certain circumstances.</li>
<li>Right to <strong>data portability</strong>, which allows the data subject to obtain and reuse their personal data for their own purposes across different services.</li>
<li>Right to <strong>object to processing</strong> in certain circumstances, you may withdraw consent at any time (where relevant).</li>
<li>Right regarding <strong>automated</strong> individual decision making and profiling.</li>
<li>Right to <strong>raise a complaint </strong>with the Information Commissioner (<a href="https://oicjersey.org/">https://oicjersey.org/</a>).</li>
</ul>
<p>If you object to processing or withdraw your consent, this may affect our ability to deliver services to you.</p>
<p>We may need to request specific information from you to help us confirm your identity to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. If you make a request, we have 28 days to respond to you once your identity is confirmed and you are not required to pay any charge for exercising your rights.</p>
<p>We endeavour to meet the highest standards when collecting and processing personal information. If you think our collection or use of personal information is unfair, misleading, or inappropriate, we encourage you to bring it to our attention. If you are unhappy with how we have used your personal data, you have the right to make a complaint at any time to the Jersey Office of the Information Commissioner (JOIC), the Jersey supervisory authority for data protection issues (<a href="https://jerseyoic.org/">https://jerseyoic.org/</a>).</p>
<p>2nd Floor<br />
5 Castle Street<br />
St. Helier<br />
Jersey<br />
JE2 3BT</p>
<p><a href="mailto:enquiries@jerseyoic.org"><strong>enquiries@jerseyoic.org</strong><br />
</a>+44 (0) 1534 716530</p>
</div>
</div>
        </div>
        </div>
        <GetInTouch/>
  </Layout>
)

export default Privacy
